.footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.copyright-notice {
  color: $blogBgColor;
}
