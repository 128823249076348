body {
  font-family: $text-font;
  color: lighten($baseColor, 90%);
  letter-spacing: 0.05rem;
  background-color: $baseColor;
}

.main {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

h1 {
  font-size: 2rem;
}

.center-content {
  max-width: 500px;
  margin: 10px auto;
}

.center-content iframe {
  max-width: 500px;
  width: 95%;
}
