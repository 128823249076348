@import url(https://fonts.googleapis.com/css?family=Thasadith);
@charset "UTF-8";
/* Settings */
.padding--large {
  padding: 30px; }

/* Components */
#bg {
  display: block;
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100vw;
  height: 100vh;
  z-index: -999; }

.dot {
  display: block;
  width: 3px;
  height: 3px;
  background: -webkit-radial-gradient(#8aeeff, #00343d);
  background: radial-gradient(#8aeeff, #00343d);
  border-radius: 50%;
  position: absolute; }

.blog {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  max-width: 950px;
  margin: 0 auto; }

.blog__post {
  width: 100%;
  padding: 15px;
  background-color: #007286;
  margin: 15px 0 15px 0;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4); }
  .blog__post ul {
    list-style: none;
    padding: 0; }
  .blog__post img {
    width: 100%; }

.blog__text {
  width: inherit; }
  .blog__text--narrow {
    width: 70%;
    margin: 0 auto; }

.blog__pic {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  max-width: 800px;
  border-radius: 5px;
  margin: 25px;
  padding: 25px;
  background-color: #eee;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4); }
  .blog__pic img {
    width: 100%;
    height: auto;
    object-fit: cover;
    padding: 15px; }

.pic__caption {
  max-width: 90%;
  padding: 15px;
  margin: 0;
  color: #333; }

.button {
  display: inline-block;
  min-width: 150px;
  text-align: center;
  text-decoration: none;
  color: #a3f1ff;
  margin: 10px;
  border: 2px solid #a3f1ff;
  border-radius: 3px;
  padding: 20px;
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.button:hover {
  background-color: #a3f1ff;
  color: #00343d; }

.button--home {
  -webkit-align-self: flex-start;
          align-self: flex-start; }

.display-content {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  min-height: 500px;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.flash__buttons-container  {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px; }

.title {
  font-family: "Thasadith", sans-serif;
  font-size: 4rem;
  letter-spacing: 0.05rem;
  color: rgba(214, 249, 255, 0.6);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none; }
  @media screen and (min-width: 600px) {
    .title {
      font-size: 5rem; } }

.footer {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 30px; }

.copyright-notice {
  color: #007286; }

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin: 0 auto; }

.horizontal-line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #007286; }

a {
  color: #70eaff;
  -webkit-transition: 0.2s;
  transition: 0.2s; }
  a:hover {
    color: #d6f9ff;
    text-decoration: none;
    cursor: pointer; }
  a:visited {
    color: #70eaff; }

.social-media {
  display: -webkit-inline-flex;
  display: inline-flex;
  list-style: none;
  padding: 1rem 0rem;
  margin: 0rem; }
  .social-media a {
    padding: 0.5rem;
    font-size: 1.5rem;
    color: rgba(214, 249, 255, 0.6);
    -webkit-transition: 0.5s;
    transition: 0.5s; }
    .social-media a:hover {
      color: rgba(255, 255, 255, 0.8); }

.portfolio__headline {
  font-size: 40px;
  font-family: "Thasadith", sans-serif; }

.portfolio__items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center; }

.portfolio-item-wrapper {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  max-width: 100%;
  margin-top: 20px;
  background-color: #007286;
  border-radius: 5px; }
  @media screen and (min-width: 750px) {
    .portfolio-item-wrapper {
      -webkit-flex-basis: calc(50% - 10px);
              flex-basis: calc(50% - 10px); } }
  .portfolio-item-wrapper:nth-child(2n + 1) {
    margin-right: 0px;
    margin-left: 0px; }
    @media screen and (min-width: 750px) {
      .portfolio-item-wrapper:nth-child(2n + 1) {
        margin-right: 20px; } }

.portfolio-item {
  padding: 20px; }

.portfolio-item__title {
  border-bottom: 1px solid white; }

.portfolio-item__iframe {
  width: 100%;
  height: 400px;
  border: 2px solid #00343d;
  border-radius: 3px;
  margin: 0px auto; }

.portfolio-item__description {
  margin-top: 10px; }

/* Elements */
body {
  font-family: "Thasadith", sans-serif;
  color: white;
  letter-spacing: 0.05rem;
  background-color: #00343d; }

.main {
  width: 100%;
  max-width: 900px;
  margin: 0 auto; }

h1 {
  font-size: 2rem; }

.center-content {
  max-width: 500px;
  margin: 10px auto; }

.center-content iframe {
  max-width: 500px;
  width: 95%; }

