.blog {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
}

.blog__post {
  width: 100%;
  padding: $spacingMedium;
  background-color: $blogBgColor;
  margin: $spacingMedium 0 $spacingMedium 0;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);

  & ul {
    list-style: none;
    padding: 0;
  }

  & img {
    width: 100%;
  }
}

.blog__text {
  width: inherit;

  &--narrow {
    width: 70%;
    margin: 0 auto;
  }
}

.blog__pic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  border-radius: 5px;
  margin: $spacingLarge;
  padding: $spacingLarge;
  background-color: #eee;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);

  & img {
    width: 100%;
    height: auto;
    object-fit: cover;
    padding: $spacingMedium;
  }
}

.pic__caption {
  max-width: 90%;
  padding: $spacingMedium;
  margin: 0;
  color: #333;
}
