.portfolio__headline {
  font-size: 40px;
  font-family: $head-font;
}

.portfolio__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.portfolio-item-wrapper {
  flex-basis: 100%;
  max-width: 100%;
  margin-top: 20px;
  background-color: $blogBgColor;
  border-radius: 5px;
  @media screen and (min-width: 750px) {
    flex-basis: calc(50% - 10px);
  }
  &:nth-child(2n + 1) {
    margin-right: 0px;
    margin-left: 0px;
    @media screen and (min-width: 750px) {
      margin-right: 20px;
    }
  }
}

.portfolio-item {
  padding: 20px;
}

.portfolio-item__title {
  border-bottom: 1px solid white;
}
.portfolio-item__iframe {
  width: 100%;
  height: 400px;
  border: 2px solid $baseColor;
  border-radius: 3px;
  margin: 0px auto;
}

.portfolio-item__description {
  margin-top: 10px;
}
