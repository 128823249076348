.button {
  display: inline-block;
  min-width: 150px;
  text-align: center;
  text-decoration: none;
  color: lighten($baseColor, 70%);
  margin: 10px;
  border: 2px solid lighten($baseColor, 70%);
  border-radius: 3px;
  padding: 20px;
  transition: 0.3s;
}

.button:hover {
  background-color: lighten($baseColor, 70%);
  color: $baseColor;
}

.button--home {
  align-self: flex-start;
}
