// Colors

$baseColor: #00343d;
$blogBgColor: #007286;

// Fonts

$text-font: "Thasadith", sans-serif;
$head-font: "Thasadith", sans-serif;

// Sizing

$bg-width: 100vw;
$bg-height: 100vh;

// Spacing

$spacingSmall: 5px;
$spacingMedium: 15px;
$spacingLarge: 25px;
